import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/origin/';

class OriginsService {

    getList() {
        return axios
            .get(API_URL + "getList", { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas pobierania listy nadpisów.");
                };
            });
    }
}

export default new OriginsService();