import React from "react";
import { Navigate } from "react-router-dom";
import AuthVerify from './../common/auth-verify';
import AuthService from './../services/auth-service';

const Authentication = ({ children }) => {
    try {
        let base64 = localStorage.getItem('user');
        if (base64 === undefined || base64 === null) return <Navigate to="/login" />;
        const user = JSON.parse(new TextDecoder().decode(AuthService.base64ToBytes(base64)));

        if (user === undefined || AuthVerify(user.token) === false) {
            return <Navigate to="/login" />;
        }

        return children;
    } catch {
        localStorage.removeItem("user");
        window.location = "/login";
    }
};

export default Authentication;