import React, { Component } from 'react';
import { AddUser } from './AddUser';
import Sidemenu from './Sidemenu';
import UserService from '../../services/user-service';

export class Users extends Component {
    static displayName = Users.name;

    constructor() {
        super();
        this.state = {
            users: []
        };

        this.getUsers();
    }

    async getUsers() {
        let result = await UserService.getUsers();
        this.setState({ users: result.data });
    }

    getStatusName(status) {
        switch (status) {
            case 0:
                return "Not Activated";
            case 1:
                return "Activated";
            case 2:
                return "Banned";
            case 3:
                return "Deleted";
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <Sidemenu page="users" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2" }}>
                        <h3 className="main-color-text">Lista użytkowników</h3>
                        <hr />
                        <AddUser />
                        <hr />
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col-1">#</th>
                                    <th scope="col-1">Imię</th>
                                    <th scope="col-1">Nazwisko</th>
                                    <th scope="col-5">Login</th>
                                    <th scope="col-3">Email</th>
                                    <th scope="col-1">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map((s, i) => {
                                    return <tr key={i}>
                                        <td className="col-1">{i + 1}</td>
                                        <td className="col-1">{s.firstName}</td>
                                        <td className="col-1">{s.lastName}</td>
                                        <td className="col-5">{s.userName}</td>
                                        <td className="col-3">{s.email}</td>
                                        <td className="col-1">{this.getStatusName(s.status)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
