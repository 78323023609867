import AuthService from './../services/auth-service';
export default function authHeader(isJson = true) {
    let base64 = localStorage.getItem('user');
    if (base64 === undefined || base64 === null) return {};
    const user = JSON.parse(new TextDecoder().decode(AuthService.base64ToBytes(base64)));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token, 'Content-Type': (isJson ? 'application/json' : 'multipart/form-data') }; // for Spring Boot back-end
    } else {
        return {};
    }
}