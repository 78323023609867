import React, { Component } from 'react';
import Sidemenu from './Sidemenu';
import SmsService from '../../services/sms-service';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    BarElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import OriginsService from '../../services/origins-service';
import UserService from '../../services/user-service';
import AuthService from '../../services/auth-service';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: { phone: "", phonePrefix: "" },
            selectedOrigin: "",
            origins: [],
            type: 0,
            from: this.getDefaultDate(),
            to: this.formatDate(Date.now()),
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            },
            data: {
                labels: [],
                datasets: []
            }
        };
        this.getChartData();
        this.getOrigins();

        ChartJS.register(
            CategoryScale,
            BarElement,
            Title,
            Tooltip,
            LinearScale,
            Legend
        );
    }

    async getOrigins() {
        let result = await OriginsService.getList();
        this.setState({ origins: result.data });
    }

    getDefaultDate() {
        var d = new Date(Date.now()),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year - 1, month, day].join('-');

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    async getChartData() {
        let result = await SmsService.getChartData({ type: this.state.type, from: this.state.from + "T00:00:00", to: this.state.to + "T23:59:59", origin: this.state.selectedOrigin });
        this.setState({ data: { ...this.state.data, labels: result.data.map((d, i) => { return d.label; }), datasets: [{ label: this.getChartName(), data: result.data.map((d, i) => { return d.data; }), backgroundColor: "green" }] } });
    }

    getChartName() {
        switch (this.state.type) {
            case 1:
                return "Wysyłka miesiecznie"
            case 2:
                return "Wysyłka kwartalnie"
            case 3:
                return "Wysyłka rocznie"
            default:
                return "Wysyłka tygodniowo"
        }
    }

    render() {
        let user = AuthService.getCurrentUser();
        let showFreeTokensInfo = user != null && user != undefined
            ? parseInt(user.freeTokens) === 0 || user.showFreeTokensPopup === true
                ? null
                : <div className="row p-3 mb-3" style={{ background: "rgb(170, 74, 68)", color: "white", borderRadius: 10 }}>
                    <span style={{ fontSize: "0.8em", display: "inline", marginRight: "0px", paddingRight: "5px" }}>
                        Aby otrzymać darmowe {AuthService.getCurrentUser().freeTokens} tokenów podaj swój numer telefonu. Zostanie do Ciebie wysłany kod po wykorzystanu którego dostaniesz darmowe tokeny.
                    </span>
                    <NavLink tag={Link} className="freeTokensInfoLink" style={{ fontSize: "1em !important", display: "inline" }} to="/settings"><span>Przejdź do strony.</span></NavLink>
                </div>
            : null;

        return (
            <div className="container-fluid">
                <div className="row row-flex-style">
                    <Sidemenu page="dashboard" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                        <h3 className="main-color-text">Panel</h3>
                        <hr />

                        {showFreeTokensInfo}

                        <div className="row">
                            <div className="col-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker defaultValue={dayjs(this.getDefaultDate())} onChange={(val) => { this.setState({ from: (val.$y) + "-" + ((parseInt(val.$M + 1)) < 10 ? "0" + (parseInt(val.$M) + 1) : (parseInt(val.$M) + 1)) + "-" + (val.$D < 10 ? "0" + val.$D : val.$D) }, () => { this.getChartData() }) }} />
                                </LocalizationProvider>
                            </div>
                            <div className="col-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker defaultValue={dayjs(this.formatDate(Date.now()))} onChange={(val) => { this.setState({ to: (val.$y) + "-" + ((parseInt(val.$M + 1)) < 10 ? "0" + (parseInt(val.$M) + 1) : (parseInt(val.$M) + 1)) + "-" + (val.$D < 10 ? "0" + val.$D : val.$D) }, () => { this.getChartData() }) }} />
                                </LocalizationProvider>
                            </div>
                            <div className="col-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="from-label">Typ wykresu</InputLabel>
                                    <Select
                                        labelId="from-label"
                                        fullWidth
                                        id="type"
                                        name="type"
                                        label="Typ wykresu"
                                        value={this.state.type}
                                        onChange={(e) => this.setState({ type: e.target.value }, () => { this.getChartData() })}
                                    >
                                        <MenuItem value={0}>Tygodniowo</MenuItem>
                                        <MenuItem value={1}>Miesięcznie</MenuItem>
                                        <MenuItem value={2}>Kwartalnie</MenuItem>
                                        <MenuItem value={3}>Rocznie</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="from-label">Nadpis</InputLabel>
                                    <Select
                                        labelId="from-label"
                                        fullWidth
                                        id="from"
                                        name="from"
                                        label="Nadpis"
                                        value={this.state.selectedOrigin}
                                        onChange={(e) => this.setState({ selectedOrigin: e.target.value }, () => this.getChartData())}
                                    >
                                        <MenuItem value={""}>{"Brak"}</MenuItem>
                                        {
                                            this.state.origins.map((o, i) => {
                                                return <MenuItem key={i} value={o.name}>{o.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <Bar
                            options={this.state.options}
                            data={this.state.data}
                            height={"10%"}
                            width={"30%"} />
                    </div>
                </div>
            </div>
        );
    }
}
