import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import OrderService from '../../services/order-service';
import ProductService from '../../services/product-service';
import UserService from "../../services/user-service";

const BuyProduct = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState(500);
    const [product, setProduct] = useState({});
    const [terms, setTerms] = useState(false);
    const [userData, setUserData] = useState({ nip: "", name: "", firstName: "", lastName: "", companyName: "", address: "", city: "", state: "", zipCode: "", phonePrefix: "48", phone: "" });

    const location = useLocation();
    const onClose = props && props.onClose;


    const getProducts = async () => {
        let result = await ProductService.getAll();
        setProducts(result.data);
        setProduct(result.data[0]);
        setLoading(false);
    }

    const getUserDate = async () => {
        let result = await UserService.getUserData();
        result.data.name = result.data.companyName;
        setUserData(result.data);
    }

    const downloadDataFromGUS = async (event) => {
        event.preventDefault();
        let result = await OrderService.getGUS(userData.nip);

        if (result.status === 200) {
            setUserData({ ...userData, name: result.data.name, address: result.data.address, zipCode: result.data.zipCode, city: result.data.city });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (product.id === products[0].id && quantity < 500) {
            alert("Aby kontynuować musisz wybrać minimum 500 kredytów.");
            return;
        }

        let data = { productId: product.id, quantity: quantity, phone: "", phonePrefix: "", nip: userData.nip, name: userData.name, address: userData.address, city: userData.city, state: "", zipCode: userData.zipCode };

        let result = await OrderService.createOrder(data);

        if (result.status === 200) {
            alert("Złożono zamówienie. Zostaniesz przekierowany do listy zamówień.")
            window.location.href = "/orders?orderId=" + result.data.id;
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerms(event.target.checked);
    };


    const showHideDiv = (type) => {
        let pDiv = document.getElementById("packageForm");
        let sDiv = document.getElementById("singleForm");
        let personDiv = document.getElementsByName("person");
        let companyDiv = document.getElementsByName("company");

        if (pDiv != null && sDiv != null && personDiv != null && companyDiv != null) {
            switch (type) {
                case "package":
                    pDiv.classList.remove("d-none");
                    sDiv.classList.add("d-none");
                    setProduct(products[1]);
                    setQuantity(1);
                    break;
                case "single":
                    pDiv.classList.add("d-none");
                    sDiv.classList.remove("d-none");
                    setProduct(products[0]);
                    setQuantity(500);
                    break;
                case "person":
                    companyDiv[0].classList.add("d-none");
                    companyDiv[1].classList.add("d-none");
                    personDiv[0].classList.remove("d-none");
                    setUserData({ ...userData, name: userData.firstName + " " + userData.lastName, nip: "" });
                    break;
                case "company":
                    personDiv[0].classList.add("d-none");
                    companyDiv[0].classList.remove("d-none");
                    companyDiv[1].classList.remove("d-none");
                    setUserData({ ...userData, nip: "" });
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        if (loading) { getUserDate(); getProducts(); }
    });

    const buySmsUnits = () => {
        return <div className="row p-3">
            <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                <Button
                    style={{ background: "#212529" }}
                    type="submit"
                    variant="contained"
                    onClick={() => onClose()}
                >
                    <i className="fa-light fa-close"></i>
                </Button>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Zamów kredyty. Aktulnie wybrano kredyty za {product?.type === 1 ? (Math.round(quantity * product.price * 100) / 100) : product.price}zł</FormLabel>
                        <RadioGroup
                            row
                            defaultValue={"single"}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="single" onClick={() => showHideDiv("single")} control={<Radio />} label="Na sztuki" />
                            <FormControlLabel value="package" onClick={() => showHideDiv("package")} control={<Radio />} label="Wybierz pakiet" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div id="packageForm" className="d-none">
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <div className="row">
                        <div className="col-6">
                            <FormControl sx={{ marginTop: 2, width: '100%' }}>
                                <InputLabel id="from-label">Wybierz pakiet</InputLabel>
                                <Select
                                    labelId="from-label"
                                    id="product"
                                    name="product"
                                    label="Wybierz pakiet"
                                    value={product}
                                    onChange={(e) => { setProduct(e.target.value); }}
                                >
                                    {products.filter(p => p.type === 0).map((p, i) => {
                                        return <MenuItem key={i} value={p}>{p.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </Box>
            </div>
            <div id="singleForm">
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                sx={{ marginTop: 2, width: '100%', mb: 0 }}
                                margin="normal"
                                required
                                id="smsCount"
                                label="Ilość smsów (minimum 500)"
                                name="smsCount"
                                value={quantity}
                                error={quantity < 500}
                                onChange={(e) => { setQuantity(e.target.value); }}
                                type="number"
                            />
                        </div>
                    </div>
                </Box>
            </div>
            <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate sx={{ mt: 1 }}>
                <div className="row gutters mt-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h4 className="mb-3 main-color-text">Dane do faktury</h4>
                    </div>

                    <div className="row d-none">
                        <div className="col-12">
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="person" onClick={() => showHideDiv("person")} control={<Radio />} label="Osoba fizyczna" />
                                    <FormControlLabel value="company" onClick={() => showHideDiv("company")} control={<Radio />} label="Działalność" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div name="person" className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-none">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="nameAndSurname"
                                label="Imie i nazwisko"
                                name="nameAndSurname"
                                value={userData.name}
                                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            />
                        </div>
                    </div>

                    <div name="company" className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="nip"
                                label="Nip"
                                name="nip"
                                value={userData.nip}
                                onChange={(e) => setUserData({ ...userData, nip: e.target.value })}
                                sx={{ wudth: "80%" }}
                            />
                            <Button
                                style={{ background: "#212529", display: "inline" }}
                                type="submit"
                                variant="contained"
                                onClick={(e) => downloadDataFromGUS(e)}
                                sx={{ wudth: "20%" }}
                            >
                                Pobierz z GUS
                            </Button>
                        </div>
                    </div>
                    <div name="company" className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Nazwa firmy"
                                name="name"
                                value={userData.name}
                                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            />
                        </div>
                    </div>

                    {/*<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <FormControl sx={{ marginTop: '15px', width: '50%' }}>*/}
                    {/*            <InputLabel id="from-label">Kod</InputLabel>*/}
                    {/*            <Select*/}
                    {/*                required*/}
                    {/*                id="phonePrefix"*/}
                    {/*                label="Prefix"*/}
                    {/*                name="phonePrefix"*/}
                    {/*                value={userData.phonePrefix}*/}
                    {/*                onChange={(e) => setUserData({ ...userData, phonePrefix: e.target.value })}*/}
                    {/*            >*/}
                    {/*                {UserService.getCountryCodes().map((cc, i) => {*/}
                    {/*                    return <MenuItem key={i} value={cc.phone}>+{cc.phone} - {cc.label}</MenuItem>*/}
                    {/*                })}*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}

                    {/*        <TextField*/}
                    {/*            style={{ marginTop: '15px', marginLeft: '2%', width: '48%' }}*/}
                    {/*            required*/}
                    {/*            id="phone"*/}
                    {/*            label="Telefon"*/}
                    {/*            name="phone"*/}
                    {/*            value={userData.phone}*/}
                    {/*            onChange={(e) => setUserData({ ...userData, phone: e.target.value })}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-0">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="address"
                                label="Adres"
                                name="address"
                                value={userData.address}
                                onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="city"
                                label="Miasto"
                                name="city"
                                value={userData.city}
                                onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                            />
                        </div>
                    </div>
                    {/*<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <TextField*/}
                    {/*            margin="normal"*/}
                    {/*            required*/}
                    {/*            fullWidth*/}
                    {/*            id="state"*/}
                    {/*            label="Województwo"*/}
                    {/*            name="state"*/}
                    {/*            value={userData.state}*/}
                    {/*            onChange={(e) => setUserData({ ...userData, state: e.target.value })}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="zip"
                                label="Kod pocztowy"
                                name="zip"
                                value={userData.zipCode}
                                onChange={(e) => setUserData({ ...userData, zipCode: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <FormControlLabel style={{ marginRight: "3px" }} control={<Checkbox onChange={handleChange} sx={{
                                color: '#212529',
                                '&.Mui-checked': {
                                    color: '#212529',
                                },
                            }} />} label="Akceptuję">
                            </FormControlLabel>
                            <a style={{ marginTop: "4px" }} href="/regulations">regulamin serwisu</a>
                        </div>
                    </div>
                </div>
                <Button
                    style={{ background: "#212529", display: "inline" }}
                    type="submit"
                    variant="contained"
                    disabled={terms == false}
                    sx={{ mt: 2, mb: 2, height: 55 }}
                >
                    Zamów z obowiązkiem zapłaty
                </Button>
            </Box>
        </div>;
    }

    return buySmsUnits();
}

export default BuyProduct;