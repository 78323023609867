import { Container } from 'reactstrap';
import { Dashboard } from "./components/Account/Dashboard";
import { Settings } from "./components/Account/Settings";
import Sms from './components/Account/Sms';
import { Users } from './components/Account/Users';
import { Wallet } from "./components/Account/Wallet";
import Forgot from "./components/Forgot";
import { Help } from "./components/Menu/Help";
import { Home } from "./components/Menu/Home";
import Login from "./components/Menu/Login";
import { Register } from "./components/Menu/Register";
import { SendSms } from "./components/Account/SendSms";
import { NotFound } from './components/NotFound';
import Order from "./components/Order";
import OrderConfirmation from "./components/OrderConfirmation";
import OrderStatus from "./components/OrderStatus";
import { Regulations } from './components/Regulations';
import Remind from './components/Remind';
import Authentication from "./provider/Authentication";
import ActivateUser from './components/ActivateUser';
import { Keys } from './components/Account/Keys';
import { AddressBook } from './components/Account/AddressBook';
import Contacts from './components/Account/Contacts';

const AppRoutes = [
    {
        index: true,
        element: <Container tag="main"><Home /></Container>
    },
    {
        path: '/help',
        element: <Container tag="main"><Help /></Container>
    },
    {
        path: '/regulations',
        element: <Container tag="main"><Regulations /></Container>
    },
    {
        path: '/login',
        element: <Container tag="main"><Login /></Container>
    },
    {
        path: '/register',
        element: <Container tag="main"><Register /></Container>
    },
    {
        path: '/forgot',
        element: <Container tag="main"><Forgot /></Container>
    },
    {
        path: '/remind/:token',
        element: <Container tag="main"><Remind /></Container>
    },
    {
        path: '/activate/:token',
        element: <Container tag="main"><ActivateUser /></Container>
    },
    {
        path: '/orderstatus',
        element: <Container tag="main"><OrderStatus /></Container>
    },
    {
        path: '/dashboard',
        element: <Authentication><Dashboard /></Authentication>
    },
    {
        path: '/sms-list',
        element: <Authentication><Sms /></Authentication>
    },
    {
        path: '/sendsms',
        element: <Authentication><SendSms /></Authentication>
    },
    {
        path: '/settings',
        element: <Authentication><Settings /></Authentication>
    },
    {
        path: '/users',
        element: <Authentication><Users /></Authentication>
    },
    {
        path: '/keys',
        element: <Authentication><Keys /></Authentication>
    },
    {
        path: '/orders',
        element: <Authentication><Wallet /></Authentication>
    },
    {
        path: '/address-book',
        element: <Authentication><AddressBook /></Authentication>
    },
    {
        path: '/contacts',
        element: <Authentication><Contacts /></Authentication>
    },
    {
        path: '*',
        element: <Container tag="main"><NotFound /></Container>
    }
];

export default AppRoutes;
