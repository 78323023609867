import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from "react-router-dom";
import AuthService from "./../services/auth-service";
import Copyright from "./Helpers/Copyright";

const Remind = () => {

    let { token } = useParams();

    const defaultTheme = createTheme();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        if (data.get('newPassword') === data.get('repeat')) {
            let result = await AuthService.remindConfirmation(data.get('newPassword'), data.get('repeat'), token);
            if (result.status === 200) {
                alert('Zmiana hasła zakończona sukcesem.');
            } else {
                alert('Wystąpił błąd podczas zmiany hasła: ' + result.data);
            }
        } else {
            alert('Hasła nie są takie same.');
        }
    };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Ustaw nowe hasło
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="newPassword"
                            label="Nowe hasło"
                            name="newPassword"
                            autoComplete="password"
                            type="password"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="repeat"
                            label="Powtórz hasło"
                            name="repeat"
                            autoComplete="repeat"
                            type="password"
                        />
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Zapisz
                        </Button>

                        <Grid container className="mt-4">
                            <Grid item xs>
                                <Link href="/login" variant="body2">
                                    {"Masz już konto? Zaloguj się!"}
                                </Link>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Link href="/register" variant="body2">*/}
                            {/*        {"Nie masz konta? Zarejestruj się!"}*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>

    );

}

export default Remind;