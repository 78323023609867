import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/order/';

class OrderService {

    getOrders() {
        return axios.get(API_URL + 'get', { headers: authHeader() });
    }

    getOrder(orderId) {
        return axios.get(API_URL + 'get/' + orderId, { headers: authHeader() });
    }

    getOrderUserData(orderId) {
        return axios.get(API_URL + 'getuserdata/' + orderId, { headers: authHeader() });
    }

    createOrder(data) {
        return axios.post(API_URL + "create", JSON.stringify(data), { headers: authHeader() });
    }

    getInvoice(id) {
        axios.get(API_URL + "invoice/" + id, { headers: authHeader(), responseType: "blob" })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });

        //return axios.get(API_URL + "invoice/" + id, { headers: authHeader() });
    }

    getGUS(nip) {
        return axios.get(API_URL + 'gus/' + nip, { headers: authHeader() });
    }
}

export default new OrderService();