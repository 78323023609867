import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, forceUpdate } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import { useLocation, Link } from 'react-router-dom';
import ContactBookService from '../../services/contact-book-service';
import UserService from '../../services/user-service';
import OriginsService from '../../services/origins-service';
import Sidemenu from './Sidemenu';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import validator from 'validator';

const customStyles = {
    content: {
        height: "70%",
        width: "70%",
        margin: 'auto',
    },
};

const customImportStyles = {
    content: {
        height: "40%",
        width: "40%",
        margin: 'auto',
        overflow: "hidden"
    },
};

const customSendModalStyles = {
    content: {
        height: "55%",
        width: "50%",
        margin: 'auto',
    },
};

const Contacts = () => {

    const location = useLocation();
    const bookId = location.state && location.state.bookId;

    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingContact, setIsUpdatingContact] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [contactBooks, setContactBooks] = useState([]);
    const [contactBook, setContactBook] = useState({ id: bookId, name: "" });
    const [currentContact, setCurrentContact] = useState({ id: 0, firstName: "", lastName: "", companyName: "", phonePrefix: "48", phone: "", email: "", contactBooks: [] });

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isImportFile, setIsImportFile] = useState(false);

    const [checked, setChecked] = useState([]);
    const [smsMessage, setSmsMessage] = useState({ contactBookId: 0, bookName: "", message: "", origin: "", contactIds: [] });
    const [origins, setOrigins] = useState([]);
    const [isSendModalOpen, setIsSendModalOpen] = useState(false);

    const getOrigins = async () => {
        let result = await OriginsService.getList();
        setOrigins(result.data);
    }

    const getContactBook = async () => {
        let result = await ContactBookService.getContactBook(contactBook.id);
        setContactBook(result.data);
    }

    const getContacts = async () => {
        let result = await ContactBookService.getAllContactsByBookId(contactBook.id);
        setContacts(result.data);
        setIsLoading(false);
    }

    const getContactBooks = async () => {
        let result = await ContactBookService.getAllContactBooks();
        setContactBooks(result.data);
    }

    const deleteContact = async (contact) => {
        confirmAlert({
            title: 'Potwierdzenie usunięcia',
            message: "Czy na pewno chcesz usuną kontakt " + contact.firstName + " " + contact.lastName + "?",
            buttons: [
                {
                    label: 'Tak',
                    onClick: async () => {
                        let result = await ContactBookService.deleteContact(contact.id);

                        if (result.status === 200) {

                            setContacts(contacts.filter(cb => cb.id !== contact.id))
                        }
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => { }
                }
            ]
        });
    }

    const addContact = async () => {
        setIsUpdatingContact(false);
        setIsModalOpen(true);
        setCurrentContact({ id: 0, firstName: "", lastName: "", companyName: "", phonePrefix: "48", phone: "", email: "", contactBooks: [contactBook] });

    }

    const editContact = async (contact) => {
        setIsUpdatingContact(true);
        setIsModalOpen(true);
        setCurrentContact(contact);

    }

    const closeModal = async () => {
        setIsModalOpen(false);
        setCurrentContact({ id: 0, firstName: "", lastName: "", companyName: "", phonePrefix: "48", phone: "", email: "", contactBooks: [contactBook] });
    }

    const addUpdateContact = async () => {

        if (currentContact.firstName === "" || currentContact.lastName === "" || currentContact.companyName === "" || currentContact.phone === "" || currentContact.phonePrefix === "") {
            alert("Wszystkie wymagane pola muszą być wypełnione.");
            return;
        }

        if (currentContact.email !== "" && validator.isEmail(currentContact.email) === false) {
            alert("Email jest niepoprawny.");
            return;
        }

        if (validator.isMobilePhone(currentContact.phonePrefix + currentContact.phone) === false) {
            alert("Numer telefonu jset niepoprawny.");
            return;
        }

        if (isUpdatingContact === true) {
            let result = await ContactBookService.updateContact(currentContact);

            if (result.status === 200) {

                let tempContacts = contacts.map((c, i) => {
                    if (c.id === result.data.id) {
                        return result.data;
                    }
                    return c;
                });
                setContacts(tempContacts);
                closeModal();
            }
        } else {
            let result = await ContactBookService.addContact(currentContact);

            if (result.status === 200) {

                let tempContacts = contacts;
                tempContacts.push(result.data);
                setContacts(tempContacts);
                closeModal();
            }
        }
    }

    const handleCheckboxChange = (e, cbId) => {
        let isOnList = currentContact.contactBooks.find(ccb => ccb.id === cbId) !== undefined;
        if (isOnList === true) {

            if (currentContact.contactBooks.length === 1) {
                alert("Kontakt musi być przypisany do przynajmniej jednej grupy.")
            } else {
                setCurrentContact({ ...currentContact, contactBooks: currentContact.contactBooks.filter(ccb => ccb.id !== cbId) })
            }

        } else {
            let tempArr = currentContact.contactBooks;
            tempArr.push(contactBooks.find(ccb => ccb.id === cbId));
            setCurrentContact({ ...currentContact, contactBooks: tempArr })
        }
    }

    const isChecked = (cbId) => {
        return currentContact.contactBooks?.find(ccb => ccb.id === cbId) !== undefined;
    }

    const showImportModal = () => {
        setIsImportModalOpen(true);
    }

    const closeImportModal = () => {
        setIsImportModalOpen(false);
    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setIsImportFile(true);
        } else {
            setIsImportFile(false);
        }
    }

    const importContacts = async () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#importFile');
        formData.append("file", imagefile.files[0]);

        let result = await ContactBookService.importContactsFile(contactBook.id, formData);
        if (result.status === 200) {
            alert("Import zakończony suksesem.");
            let tempArr = contacts;
            for (let i = 0; i < result.data.length; i++) {
                tempArr.push(result.data[i]);
            }
            setContacts(tempArr);
            closeImportModal();
        }
    }

    const isSelected = (contact) => {
        return checked?.indexOf(sc => sc === contact.id) !== -1;
    }

    const handleSelectUnselectAll = () => {

        if (checked.length === contacts.length) {
            setChecked([]);
        } else {
            const newChecked = [...checked];

            for (let i = 0; i < contacts.length; i++) {
                const currentIndex = checked.indexOf(contacts[i].id);

                if (currentIndex === -1) {
                    newChecked.push(contacts[i].id);
                }

            }
            setChecked(newChecked);
        }
    }

    const handleSelection = (contact) => {

        const currentIndex = checked.indexOf(contact.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(contact.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const showSendModal = async () => {
        setIsSendModalOpen(true);
        setSmsMessage({ contactBookId: contactBook.id, bookName: contactBook.name, message: "", origin: "", contactIds: checked });
    }

    const closeSendModal = async () => {
        setIsSendModalOpen(false);
        setSmsMessage({ contactBookId: 0, bookName: "", message: "", origin: "", contactIds: [] });
    }

    const sendMessageToContacts = async () => {
        setIsSendModalOpen(false);
        confirmAlert({
            title: 'Potwierdzenie wysłania',
            message: "Czy na pewno chcesz wysłać tę wiadomość do wybranych kontaktów z bazy " + smsMessage.bookName + "?",
            buttons: [
                {
                    label: 'Tak',
                    onClick: async () => {
                        let result = await ContactBookService.sendMessageToContactBook(smsMessage);
                        if (result.status === 200) {
                            alert("Wszystkie wiadomości zostały zakolejkowane.");
                        }
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => { }
                }
            ]
        });
    }

    useEffect(() => {
        if (isLoading) { getOrigins(); getContactBook(); getContactBooks(); getContacts(); }
    });

    return (
        <div className="container-fluid">
            <Modal
                isOpen={isModalOpen}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edycja kontaktu"
            >
                <div className="row p-1">
                    <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => closeModal()}
                        >
                            <i className="fa-light fa-close"></i>
                        </Button>
                    </div>
                    <div className="row">
                        <h2>{isUpdatingContact ? "Edycja kontaktu" : "Dodaj kontakt"}</h2>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="fname"
                                    label="Imię"
                                    name="fname"
                                    value={currentContact.firstName}
                                    error={currentContact.firstName === ""}
                                    onChange={(e) => setCurrentContact({ ...currentContact, firstName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lname"
                                    label="Nazwisko"
                                    name="lname"
                                    value={currentContact.lastName}
                                    error={currentContact.lastName === ""}
                                    onChange={(e) => setCurrentContact({ ...currentContact, lastName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="cname"
                                    label="Nazwa firmy"
                                    name="cname"
                                    value={currentContact.companyName}
                                    error={currentContact.companyName === ""}
                                    onChange={(e) => setCurrentContact({ ...currentContact, companyName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={currentContact.email}
                                    error={currentContact.email !== "" && validator.isEmail(currentContact.email) === false}
                                    onChange={(e) => setCurrentContact({ ...currentContact, email: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                                <Select
                                    style={{ marginTop: '15px', width: '20%' }}
                                    required
                                    id="phonePrefix"
                                    label="Prefix"
                                    name="phonePrefix"
                                    value={currentContact.phonePrefix}
                                    error={currentContact.phonePrefix === ""}
                                    onChange={(e) => setCurrentContact({ ...currentContact, phonePrefix: e.target.value })}
                                >
                                    {UserService.getCountryCodes().map((cc, i) => {
                                        return <MenuItem key={i} value={cc.phone}>+{cc.phone} - {cc.label}</MenuItem>
                                    })}
                                </Select>
                                <TextField
                                    style={{ marginTop: '15px', marginLeft: '2%', width: '78%' }}
                                    required
                                    id="phone"
                                    label="Telefon"
                                    name="phone"
                                    value={currentContact.phone}
                                    error={validator.isMobilePhone(currentContact.phonePrefix + currentContact.phone) === false}
                                    onChange={(e) => setCurrentContact({ ...currentContact, phone: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 mt-3 mb-0" style={{ paddingBottom: "0px" }}>Przynależność do grupy</div>
                        <div className="col-12 mt-0">
                            {contactBooks.map((cb, i) => {
                                return <FormControlLabel key={i} sx={{ mt: 0 }} required control={<Checkbox checked={isChecked(cb.id)} onChange={(e) => { handleCheckboxChange(e, cb.id) }} />} label={cb.name} />
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button
                                onClick={(e) => { addUpdateContact() }}
                                style={{ background: "#212529", display: "inline" }}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2, mb: 2, height: 55 }}
                            >
                                Zapisz
                            </Button>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                isOpen={isImportModalOpen}
                style={customImportStyles}
                ariaHideApp={false}
                contentLabel="Import kontaktów"
            >
                <div className="row p-1">
                    <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => closeImportModal()}
                        >
                            <i className="fa-light fa-close"></i>
                        </Button>
                    </div>
                    <div className="row">
                        <h2>Import kontaktów</h2>
                    </div>
                    <div className="row">
                        <p><b>Aby zaimportować kontakty, dodaj plik csv z danymi w formacie:</b></p>

                        <p>
                            imie;nazwisko;firma;prefix;telefon;email <br />
                            Jan;Kowalski;JanKow;48;666666666;jankowalski@mwc.pl
                        </p>
                        <div className="col-12">
                            <input id="importFile" type="file" accept=".csv" onChange={(e) => { handleFileChange(e) }} className="form-control"></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button
                                onClick={(e) => { importContacts() }}
                                style={{ background: "#212529", display: "inline" }}
                                type="submit"
                                disabled={isImportFile === false}
                                variant="contained"
                                sx={{ mt: 2, mb: 2, height: 55 }}
                            >
                                Importuj
                            </Button>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                isOpen={isSendModalOpen}
                style={customSendModalStyles}
                ariaHideApp={false}
                contentLabel="Wysyłanie wiadomości do bazy kontaktów"
            >
                <div className="row p-1">
                    <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => closeSendModal()}
                        >
                            <i className="fa-light fa-close"></i>
                        </Button>
                    </div>
                    <div className="row">
                        <h2>Wyślij wiadomość</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nazwa bazy klientów"
                                    disabled
                                    name="name"
                                    value={smsMessage.bookName}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    required
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    fullWidth
                                    id="message"
                                    label="Wiadomość"
                                    name="message"
                                    value={smsMessage.message}
                                    onChange={(e) => setSmsMessage({ ...smsMessage, message: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <FormControl sx={{ marginTop: 2, width: '100%' }}>
                                    <InputLabel id="from-label">Nadpis</InputLabel>
                                    <Select
                                        labelId="from-label"
                                        required
                                        fullWidth
                                        id="from"
                                        name="from"
                                        label="Nadpis"
                                        value={smsMessage.origin}
                                        onChange={(e) => setSmsMessage({ ...smsMessage, origin: e.target.value })}
                                    >
                                        {
                                            origins.map((o, i) => {
                                                return <MenuItem key={i} value={o.name}>{o.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Button
                                onClick={(e) => { sendMessageToContacts() }}
                                style={{ background: "#212529", display: "inline" }}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2, mb: 2, height: 55 }}
                            >
                                Wyślij
                            </Button>
                        </div>
                    </div>

                </div>
            </Modal>
            <div className="row row-flex-style">
                <Sidemenu page="addressBook" />
                <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                    <h3 className="main-color-text" style={{ display: "inline" }}><Link to={{ pathname: `/address-book` }}>Baza kontaktów</Link> >> <b>{contactBook.name}</b></h3>
                    <Button onClick={() => { addContact() }} style={{ background: "#212529", display: "inline", float: "right" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 15, fontWeight: "bold" }}>
                        <i className="fa fa-plus"></i> <span style={{ marginLeft: 10 }}>Dodaj kontakt</span>
                    </Button>
                    <Button onClick={() => { showImportModal() }} style={{ background: "#212529", display: "inline", float: "right" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 15, fontWeight: "bold" }}>
                        <i className="fa fa-download"></i> <span style={{ marginLeft: 10 }}>Zaimportuj</span>
                    </Button>
                    <Button disabled={checked.length === 0} onClick={() => { showSendModal() }} style={{ background: "#212529", display: "inline", float: "right" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 15, fontWeight: "bold" }}>
                        <i className="fa fa-paper-plane"></i> <span style={{ marginLeft: 10 }}>Wyślij</span>
                    </Button>
                    <hr />
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col-1">
                                    <Checkbox onChange={(e) => handleSelectUnselectAll()} sx={{
                                        color: '#212529',
                                        '&.Mui-checked': {
                                            color: '#212529',
                                        },
                                    }} />
                                </th>
                                <th scope="col-1">Imię</th>
                                <th scope="col-2">Nazwisko</th>
                                <th scope="col-2">Firma</th>
                                <th scope="col-2">Telefon</th>
                                <th scope="col-2">Email</th>
                                <th scope="col-2">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((s, i) => {
                                return <tr key={i}>
                                    <td className="col-1">
                                        <Checkbox checked={checked.indexOf(s.id) !== -1} onChange={(e) => handleSelection(s)} sx={{
                                            color: '#212529',
                                            '&.Mui-checked': {
                                                color: '#212529',
                                            },
                                        }} />
                                    </td>
                                    <td className="col-1">{s.firstName}</td>
                                    <td className="col-2">{s.lastName}</td>
                                    <td className="col-2">{s.companyName}</td>
                                    <td className="col-2">{s.phonePrefix} {s.phone}</td>
                                    <td className="col-2">{s.email}</td>
                                    <td className="col-2" style={{ textAlign: "end", paddingRight: "0px" }}>
                                        <Button onClick={() => { editContact(s) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                            edycja
                                        </Button>
                                        <Button onClick={() => { deleteContact(s) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                            Usuń
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}


export default Contacts;