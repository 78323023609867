import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import UserService from "./../../services/user-service";
import AuthService from "./../../services/auth-service";
import Sidemenu from './Sidemenu';
import validator from 'validator';


export class Settings extends Component {
    static displayName = Settings.name;

    constructor(props) {
        super(props);

        this.state = {
            userdata: { firstName: "", lastName: "", website: "", phone: "", phonePrefix: "", nip: "", companyName: "", address: "", city: "", state: "", zipCode: "", callbackUrl: "" },
            passwordChange: { npass: "", rpass: "", opass: "" },
            token: "",
            user: { freeTokens: 0, showFreeTokensPopup: false }
        };

        this.getUserDate();
        this.getUser();
        this.handleUpdateDataSubmit = this.handleUpdateDataSubmit.bind(this);
        this.handlePasswordChangeSubmit = this.handlePasswordChangeSubmit.bind(this);
        this.handleCodeSending = this.handleCodeSending.bind(this);
    }

    async getUserDate() {
        let result = await UserService.getUserData();
        this.setState({ userdata: result.data });
    }

    async getUser() {
        this.setState({ user: await AuthService.refreshUser() });
    }

    async getCountryCodes() {
        let result = await UserService.getCountryCodes();
        this.setState({ countryCodes: result.data });
    }

    async handleUpdateDataSubmit(event) {
        event.preventDefault();

        let result = await UserService.updateUserData(this.state.userdata);

        if (result.status === 200) {
            alert('Zapisano pomyślnie.');
            this.getUser();
        }
    }

    async handlePasswordChangeSubmit(event) {
        event.preventDefault();

        let result = await UserService.changePassword(this.state.passwordChange);

        if (result.status === 200) {
            alert('Hasło zmienione pomyślnie.');
        }

        this.setState({ passwordChange: { npass: "", rpass: "", opass: "" } });
    }

    async handleCodeSending() {
        let result = await UserService.verifyFreeTokens(this.state.token);
        if (result.status === 200) {
            window.location.reload();
        }
    }

    isValidNip(nip) {
        if (typeof nip !== 'string')
            return false;

        nip = nip.replace(/[\\-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        return sum % 11 === controlNumber;
    }

    freeTokensInfo() {
        return this.state.user.showFreeTokensPopup
            ? <div className="row m-auto">
                <div className="col-9 p-3 " style={{ border: "1px black solid", background: "#f2f2f2", color: "#212529", }}>
                    <div className="row">
                        <div className="col-12 mt-1 mb-3" style={{ color: "#212529" }}>
                            Aby otrzymać <b>darmowe</b> {this.state.user.freeTokens} tokenów. Należy wypełnić poniższy formularz. Kod sms został wysłany na numer telefonu podany poniżej.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">
                            <div className="form-group">
                                <TextField
                                    style={{ background: "white" }}
                                    fullWidth
                                    id="token"
                                    label="Kod sms"
                                    name="token"
                                    value={this.state.token}
                                    onChange={(e) => this.setState({ token: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <Button
                                style={{ background: "#212529", padding: "16px" }}
                                onClick={(e) => { this.handleCodeSending() }}
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Wyślij
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
            : null;
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row row-flex-style">
                    <Sidemenu page="settings" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                        <h2 className="main-color-text">Ustawienia</h2>
                        <hr />

                        {this.freeTokensInfo()}

                        <Box component="form" onSubmit={this.handleUpdateDataSubmit} noValidate sx={{ mt: 1 }}>
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                <div className="row gutters">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 className="mb-3 main-color-text">Dane osobowe</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="Imię"
                                                name="firstName"
                                                error={this.state.userdata.firstName === ""}
                                                value={this.state.userdata.firstName}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, firstName: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Nazwisko"
                                                name="lastName"
                                                error={this.state.userdata.lastName === ""}
                                                value={this.state.userdata.lastName}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, lastName: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                style={{ marginTop: '15px', }}
                                                disabled
                                                fullWidth
                                                id="email"
                                                type="email"
                                                label="Email"
                                                name="email"
                                                value={AuthService.getCurrentUser().email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select
                                                style={{ marginTop: '15px', width: '20%' }}
                                                required
                                                id="phonePrefix"
                                                label="Prefix"
                                                name="phonePrefix"
                                                value={this.state.userdata.phonePrefix}
                                                error={this.state.userdata.phonePrefix === ""}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, phonePrefix: e.target.value } })}
                                            >
                                                {UserService.getCountryCodes().map((cc, i) => {
                                                    return <MenuItem key={i} value={cc.phone}>+{cc.phone} - {cc.label}</MenuItem>
                                                })}
                                            </Select>
                                            <TextField
                                                style={{ marginTop: '15px', marginLeft: '2%', width: '78%' }}
                                                required
                                                id="phone"
                                                label="Telefon"
                                                name="phone"
                                                error={validator.isMobilePhone(this.state.userdata.phonePrefix + this.state.userdata.phone) === false}
                                                value={this.state.userdata.phone}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, phone: e.target.value } })}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row gutters mt-3">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 className="mb-3 main-color-text">Dane do faktury</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-0">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="companyName"
                                                label="Nazwa firmy"
                                                name="companyName"
                                                error={this.state.userdata.companyName === ""}
                                                value={this.state.userdata.companyName}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, companyName: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-0">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="nip"
                                                label="NIP"
                                                name="nip"
                                                error={this.isValidNip(this.state.userdata.nip) === false}
                                                value={this.state.userdata.nip}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, nip: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-0">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="address"
                                                label="Adres"
                                                name="address"
                                                error={this.state.userdata.address === ""}
                                                value={this.state.userdata.address}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, address: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="city"
                                                label="Miasto"
                                                name="city"
                                                error={this.state.userdata.city === ""}
                                                value={this.state.userdata.city}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, city: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="state"
                                                label="Województwo"
                                                name="state"
                                                error={this.state.userdata.state === ""}
                                                value={this.state.userdata.state}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, state: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="zip"
                                                label="Kod pocztowy"
                                                name="zip"
                                                error={validator.isPostalCode(this.state.userdata.zipCode, 'any') === false}
                                                value={this.state.userdata.zipCode}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, zipCode: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row gutters">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 className="mb-3 main-color-text">Dodatkowe informacje</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                fullWidth
                                                id="callbackUrl"
                                                label="Callback url"
                                                name="callbackUrl"
                                                value={this.state.userdata.callbackUrl}
                                                onChange={(e) => this.setState({ userdata: { ...this.state.userdata, callbackUrl: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row gutters mt-3">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <div className="text-right">
                                            <Button
                                                style={{ background: "#212529" }}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Aktualizuj
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>

                        <Box component="form" onSubmit={this.handlePasswordChangeSubmit} noValidate sx={{ mt: 1 }}>
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                <div className="row gutters">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 className="mb-3 main-color-text">Zmiana hasła</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                fullWidth
                                                id="newpass"
                                                label="Nowe hasło"
                                                name="newpass"
                                                type="password"
                                                value={this.state.passwordChange.npass}
                                                onChange={(e) => this.setState({ passwordChange: { ...this.state.passwordChange, npass: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                fullWidth
                                                id="rnewpass"
                                                label="Powtórz hasło"
                                                name="rnewpass"
                                                type="password"
                                                value={this.state.passwordChange.rpass}
                                                onChange={(e) => this.setState({ passwordChange: { ...this.state.passwordChange, rpass: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                                        <div className="form-group">
                                            <TextField
                                                required
                                                fullWidth
                                                id="oldpass"
                                                label="Aktualne hasło"
                                                name="oldpass"
                                                type="password"
                                                value={this.state.passwordChange.opass}
                                                onChange={(e) => this.setState({ passwordChange: { ...this.state.passwordChange, opass: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row gutters mt-3">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                        <div className="text-right">
                                            <Button
                                                style={{ background: "#212529" }}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Zmień hasło
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        );
    }
}
