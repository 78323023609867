import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSurprise } from '@fortawesome/free-solid-svg-icons'

export class NotFound extends Component {
    static displayName = NotFound.name;

    render() {
        return (
            <div className="text-center w-100 h-100" style={{ marginTop: "15%" }}>
                <h1 className="w-100" style={{ fontSize: "500%" }}>404 <FontAwesomeIcon icon={faFaceSurprise} /></h1>
                <h3 className="w-100" style={{ fontSize: "400%" }}>Strona nie istnieje</h3>
            </div>
        );
    }
}
