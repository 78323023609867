import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <section>
                <h1>API do Wysyłki SMS</h1>
                <p>Witaj na stronie poświęconej naszemu API do wysyłki wiadomości SMS.</p>

                <h2>1. Prosta Integracja</h2>
                <p>Dzięki intuicyjnemu interfejsowi i bogatej dokumentacji integracja z naszym API jest szybka i łatwa. Oferujemy obszerne przykłady kodu dla różnych języków programowania, co pozwala dostosować integrację do indywidualnych potrzeb.</p>

                <h2>2. Wysoka Wydajność i Niezawodność</h2>
                <p>Nasze API działa na wydajnych serwerach, co gwarantuje szybką i niezawodną dostawę wiadomości SMS. Zastosowanie zaawansowanych technologii umożliwia nam utrzymanie wysokiej dostępności usługi, co sprawia, że możesz być pewien, że Twoje wiadomości dotrą do odbiorców w odpowiednim czasie.</p>

                <h2>3. Zabezpieczona Komunikacja</h2>
                <p>Dbamy o bezpieczeństwo przesyłanych danych, zapewniając zabezpieczoną komunikację. Twoje informacje są u nas w bezpiecznych rękach.</p>

            </section>
        );
    }
}
