import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/key/';

class KeyService {

    addKey(data) {
        return axios.post(API_URL + "add", JSON.stringify(data), { headers: authHeader() });
    }

    deleteKey(id) {
        return axios.delete(API_URL + "delete/" + id, { headers: authHeader() });
    }

    getList() {
        return axios
            .get(API_URL + "getAll", { headers: authHeader() })
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    console.log("error", response);
                    return [];
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas logowania.");
                };
            });
    }
}

export default new KeyService();