import React, { Component } from 'react';
import Sidemenu from './Sidemenu';
import KeyService from '../../services/key-service';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export class Keys extends Component {
    constructor() {
        super();
        this.state = {
            showKeyValue: null,
            keys: [],
            keyName: ""
        };

        this.getKeys();
    }

    async getKeys() {
        let result = await KeyService.getList();
        this.setState({ keys: result });
    }

    async handleSubmit() {
        if (this.state.keyName.length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await KeyService.addKey({ name: this.state.keyName });

        //show key in modal

        if (result.status === 200) {
            this.getKeys();
            this.setState({ keyName: "" });
            alert("Klucz został utworzony!")
        }
    };

    async deleteKey(id) {
        let result = await KeyService.deleteKey(id);
        if (result.status === 200) {
            this.getKeys();
            alert("Klucz został usunięty!")
        }
    }

    async showHideKeyValue(keyId) {
        if (this.state.showKeyValue === keyId) {
            this.setState({ showKeyValue: undefined });
        } else {
            this.setState({ showKeyValue: keyId });
        }
    }

    addForm() {
        return <div style={{ textAlign: "left" }}>
            <TextField
                sx={{ width: '20%', mr: 2 }}
                margin="normal"
                required
                id="name"
                label="Nazwa klucza"
                name="name"
                type="name"
                value={this.state.keyName}
                onChange={(e) => { this.setState({ keyName: e.target.value }) }}
            />
            <Button
                style={{ background: "#212529" }}
                onClick={(e) => this.handleSubmit()}
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2, minWidth: 240, height: 55 }}
            >
                Dodaj
            </Button>
        </div>
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row row-flex-style">
                    <Sidemenu page="keys" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                        <h3 className="main-color-text">Lista kluczy autoryzacyjnych</h3>
                        <hr />
                        <p>Zakładka do zarządzania kluczami autoryzacyjnymi. Można je wykorzystać do wysyłania zapytań API, bez potrzeby logowania i generowania tokenu JWT. Aby użyć klucz autowyzacyjny należy dodać do zapytania nagłówek "Sms-Token XXXX", gdzie XXXX to wygenerowana wartość klucza.</p>
                        <p><b>Należy zapamiętać, że klucze są ważne przez określony czas.</b></p>
                        <hr />
                        {this.addForm()}
                        <hr />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col-1">#</th>
                                    <th scope="col-2">Nazwa</th>
                                    <th scope="col-3">Wartość</th>
                                    <th scope="col-2">Wygasa</th>
                                    {/*<th scope="col-2">Ostatnie użycie</th>*/}
                                    <th scope="col-2">Utworzono</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.keys.map((s, i) => {
                                    return <tr key={i}>
                                        <td className="col-1"><Button onClick={() => { this.deleteKey(s.id) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                            Usuń
                                        </Button></td>
                                        <td className="col-2">{s.name}</td>
                                        <td className="col-3"><span style={{ cursor: "pointer" }} onClick={(e) => { this.showHideKeyValue(s.id) }}>{this.state.showKeyValue === s.id ? s.value : "******************** (kliknij, aby odkryć)"} </span></td>
                                        <td className="col-2">{s.expireAt.split("T")[0]} {s.expireAt.split("T")[1].split(".")[0]}</td>
                                        {/*<td className="col-2">{s.lastUsedAt != null ? s.lastUsedAt.split("T")[0] : null} {s.lastUsedAt != null ? s.lastUsedAt.split("T")[1].split(".")[0] : null}</td>*/}
                                        <td className="col-2">{s.createdAt.split("T")[0]} {s.createdAt.split("T")[1].split(".")[0]}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
