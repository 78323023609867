import React, { Component } from 'react';

export class Help extends Component {
    static displayName = Help.name;

    render() {
        return (
            <section>
                <h1>Pomoc - API do Wysyłki SMS</h1>
                <p>Witaj w sekcji pomocy dotyczącej naszego API do wysyłki wiadomości SMS. Poniżej znajdziesz informacje, które pomogą Ci w szybkiej i skutecznej integracji z naszym interfejsem programowania aplikacji.</p>

                <h2>Kroki Integracji:</h2>
                <ol>
                    <li>Zdobądź dane autoruzacyjne API: Zarejestruj się w naszym systemie i otrzymaj dostęp do usługi wysyłki SMS.</li>
                    <li>Zapoznaj się z dokumentacją: Przeczytaj naszą <a href="https://sms.mwc.pl/docs">dokumentację</a> API, aby zrozumieć dostępne funkcje, parametry i przykładowe zapytania. </li>
                    <li>Integracja z kodem: Skorzystaj z przykładowego kodu integracyjnego dostępnego poniżej, dostosowując go do własnych potrzeb.</li>
                    <li>Testowanie: Przetestuj integrację, wysyłając testowe wiadomości i sprawdzając odpowiedzi API.</li>
                    <li>Monitorowanie: Monitoruj wydajność i statystyki dostarczenia wiadomości, dostępne w panelu administracyjnym.</li>
                </ol>

                <h2>Przykładowe Zapytanie:</h2>
                <div class="code">
                    <p>Endpoint: <strong>POST https://sms.mwc.pl/api/sms/send</strong></p>
                    <p>Body:</p>
                    <pre>
                        <code class="code">
                            &#123;
                            "user": "administrator",
                            "password":"******",
                            "from": "SMS API MWC",
                            "to": "1234567890",
                            "message": "Witaj, to jest testowa wiadomość SMS!"
                            &#125;
                        </code>
                    </pre>
                </div>

                <div class="note">
                    <p><strong>Wskazówka:</strong> Zalecamy używanie bezpiecznej komunikacji HTTPS podczas korzystania z API.</p>
                </div>

                <h2>Autoryzacja przy pomocy tokenu JWT:</h2>
                <p>Endpointy, które nie posiadają parametru login i hasło należy autoryzorać dodając nagłówek Bearer z tokenem JWT. Token można wygenerować przesyłając dane logowania na endpoint:</p>
                <div class="code">
                    <p>Endpoint: <strong>POST https://sms.mwc.pl/api/user/generateJWTtoken</strong></p>
                    <p>Body:</p>
                    <pre>
                        <code class="code">
                            &#123;
                            "email": "administrator",
                            "password":"******"
                            &#125;
                        </code>
                    </pre>
                </div>

                <div class="note">
                    <p><strong>Wskazówka:</strong> W polu email może być przesłany także numer login lub email użyty przy tworzeniu konta.</p>
                </div>
            </section>
        );
    }
}
