import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SmsService from '../../services/sms-service';
import OriginsService from '../../services/origins-service';
import Sidemenu from './Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import AuthService from '../../services/auth-service';
export class SendSms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: "MWC",
            origins: [],
            parentCallback: props
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getOrigins();
    }

    async getOrigins() {
        let result = await OriginsService.getList();
        this.setState({ origins: result.data });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let base64 = localStorage.getItem('user');
        let user = JSON.parse(new TextDecoder().decode(AuthService.base64ToBytes(base64)));

        if (data.get('to').length === 0 || data.get('from').length === 0 || data.get('message').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await SmsService.send({ username: user.username, to: data.get('to'), from: data.get('from'), message: data.get('message') });

        if (result.status === 200) {
            document.getElementById("to").value = "";
            document.getElementById("message").value = "";
            alert("Wiadomość wysłana poprawnie!")
        }
    };

    sendForm() {
        return <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                required
                id="to"
                label="Adresat"
                name="to"
            />
            <FormControl sx={{ marginTop: 2, width: '100%' }}>
                <InputLabel id="from-label">Nadpis</InputLabel>
                <Select
                    labelId="from-label"
                    fullWidth
                    id="from"
                    name="from"
                    label="Nadpis"
                    value={this.state.from}
                    onChange={(e) => this.setState({ from: e.target.value })}
                >
                    {
                        this.state.origins.map((o, i) => {
                            return <MenuItem key={i} value={o.name}>{o.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <TextField
                margin="normal"
                fullWidth
                id="message"
                label="Treść wiadomości"
                name="message"
            />
            <Button
                style={{ background: "#212529" }}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, minWidth: 240 }}
            >
                <FontAwesomeIcon icon={faPaperPlane} /> <span style={{ marginLeft: 10 }}>Wyślij</span>
            </Button>
        </Box>
    }



    render() {
        return <div className="container-fluid">
            <div className="row row-flex-style">
                <Sidemenu page="sendsms" />
                <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                    <h3 className="main-color-text">Wyślij wiadomość SMS</h3>
                    <hr />
                    {this.sendForm()}
                </div>
            </div>
        </div>
    }
}
