import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import UserService from '../../services/user-service';

export class AddUser extends Component {

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('email').length === 0 || data.get('userName').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await UserService.addUser({ email: data.get('email'), userName: data.get('userName') });

        console.log(result);

        if (result.data.status === 0) {

            document.getElementById("email").value = "";
            document.getElementById("userName").value = "";
            alert("Użytkownik został utworzony! Aby go aktywować kliknij w link wysłany na adres email.")
        }
    };

    addForm() {
        return <div style={{ textAlign: "left" }}>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                />
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="userName"
                    label="Login"
                    name="userName"
                    type="text"
                />
                <Button
                    style={{ background: "#212529" }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2, minWidth: 240, height: 55 }}
                >
                    Dodaj
                </Button>
            </Box>
        </div>

    }

    render() {
        return this.addForm();
    }
}
