import React, { Component } from 'react';
import Sidemenu from './Sidemenu';
import ContactBookService from '../../services/contact-book-service';
import Button from '@mui/material/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import OriginsService from '../../services/origins-service';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const customStyles = {
    content: {
        height: "350px",
        width: "50%",
        margin: 'auto',
    },
};

const customSendModalStyles = {
    content: {
        height: "500px",
        width: "50%",
        margin: 'auto',
    },
};

export class AddressBook extends Component {
    constructor() {
        super();
        this.state = {
            isUpdatingBook: false,
            isModalOpen: false,
            isSendModalOpen: false,
            contactBooks: [],
            currentContactBook: { id: 0, name: "", contacts: [] },
            smsMessage: { contactBookId: 0, bookName: "", message: "", origin: "" },
            origins: []
        };
        this.getContactBooks();
        this.getOrigins();
    }


    async getContactBooks() {
        let result = await ContactBookService.getAllContactBooks();
        this.setState({ contactBooks: result.data });
    }

    async getOrigins() {
        let result = await OriginsService.getList();
        this.setState({ origins: result.data });
    }

    async deleteBook(book) {
        confirmAlert({
            title: 'Potwierdzenie usunięcia',
            message: "Czy na pewno chcesz usunąc książkę adresową " + book.name + "?",
            buttons: [
                {
                    label: 'Tak',
                    onClick: async () => {
                        let result = await ContactBookService.deleteContactBook(book.id);

                        if (result.status === 200) {
                            this.setState({ contactBooks: this.state.contactBooks.filter(cb => cb.id != book.id) })
                        }
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => { }
                }
            ]
        });
    }

    async addBook() {
        this.setState({ isUpdatingBook: false, isModalOpen: true, editedModal: { id: 0, name: "", contacts: [] } });

    }

    async editBook(book) {
        this.setState({ isUpdatingBook: true, isModalOpen: true, currentContactBook: book });
    }

    async closeModal() {
        this.setState({ isModalOpen: false, currentContactBook: { id: 0, name: "", contacts: [] } });
    }

    async showSendModal(book) {
        this.setState({ isSendModalOpen: true, smsMessage: { contactBookId: book.id, bookName: book.name, message: "", origin: "" } });
    }

    async closeSendModal() {
        this.setState({ isSendModalOpen: false, smsMessage: { contactBookId: 0, bookName: "", message: "", origin: "" } });
    }

    async sendMessageToContacts() {
        this.setState({ isSendModalOpen: false });
        confirmAlert({
            title: 'Potwierdzenie wysłania',
            message: "Czy na pewno chcesz wysłać tę wiadomość do całej bazy adresów " + this.state.smsMessage.bookName + "?",
            buttons: [
                {
                    label: 'Tak',
                    onClick: async () => {
                        let result = await ContactBookService.sendMessageToContactBook(this.state.smsMessage);
                        if (result.status === 200) {
                            alert("Wszystkie wiadomości zostały zakolejkowane.");
                        }
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => { }
                }
            ]
        });
    }

    async addUpdateBook() {
        if (this.state.currentContactBook.name === "") {
            alert("Nazwa nie może być pusta.");
            return;
        }

        if (this.state.isUpdatingBook === true) {
            let result = await ContactBookService.updateContactBook(this.state.currentContactBook);

            if (result.status === 200) {

                let tempContactBooks = this.state.contactBooks.map((b, i) => {
                    if (b.id === result.data.id) {
                        return result.data;
                    }
                    return b;
                });
                this.setState({ contactBooks: tempContactBooks });
                this.closeModal();
            }
        } else {
            let result = await ContactBookService.addContactBook(this.state.currentContactBook);

            if (result.status === 200) {

                let tempContactBooks = this.state.contactBooks;
                tempContactBooks.push(result.data);
                this.setState({ contactBooks: tempContactBooks });
                this.closeModal();
            }
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Modal
                    isOpen={this.state.isModalOpen}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Edycja bazy klientów"
                >
                    <div className="row p-1">
                        <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                            <Button
                                style={{ background: "#212529" }}
                                type="submit"
                                variant="contained"
                                onClick={() => this.closeModal()}
                            >
                                <i className="fa-light fa-close"></i>
                            </Button>
                        </div>
                        <div className="row">
                            <h2>{this.state.isUpdatingBook ? "Edycja grupy" : "Dodaj grupę"}</h2>
                        </div>
                        <div className="row">
                            <div name="company" className="col-12">
                                <div className="form-group">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Nazwa grupy"
                                        name="name"
                                        value={this.state.currentContactBook.name}
                                        error={this.state.currentContactBook.name === ""}
                                        onChange={(e) => this.setState({ currentContactBook: { ...this.state.currentContactBook, name: e.target.value } })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Button
                                    onClick={(e) => { this.addUpdateBook() }}
                                    style={{ background: "#212529", display: "inline" }}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2, height: 55 }}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>

                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.isSendModalOpen}
                    style={customSendModalStyles}
                    ariaHideApp={false}
                    contentLabel="Wysyłanie wiadomości do bazy kontaktów"
                >
                    <div className="row p-1">
                        <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                            <Button
                                style={{ background: "#212529" }}
                                type="submit"
                                variant="contained"
                                onClick={() => this.closeSendModal()}
                            >
                                <i className="fa-light fa-close"></i>
                            </Button>
                        </div>
                        <div className="row">
                            <h2>Wyślij wiadomość</h2>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Nazwa bazy klientów"
                                        disabled
                                        name="name"
                                        value={this.state.smsMessage.bookName}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <TextField
                                        margin="normal"
                                        required
                                        multiline
                                        minRows={3}
                                        maxRows={3}
                                        fullWidth
                                        id="message"
                                        label="Wiadomość"
                                        name="message"
                                        value={this.state.smsMessage.message}
                                        onChange={(e) => this.setState({ smsMessage: { ...this.state.smsMessage, message: e.target.value } })}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <FormControl sx={{ marginTop: 2, width: '100%' }}>
                                        <InputLabel id="from-label">Nadpis</InputLabel>
                                        <Select
                                            labelId="from-label"
                                            required
                                            fullWidth
                                            id="from"
                                            name="from"
                                            label="Nadpis"
                                            value={this.state.smsMessage.origin}
                                            onChange={(e) => this.setState({ smsMessage: { ...this.state.smsMessage, origin: e.target.value } })}
                                        >
                                            {
                                                this.state.origins.map((o, i) => {
                                                    return <MenuItem key={i} value={o.name}>{o.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <Button
                                    onClick={(e) => { this.sendMessageToContacts() }}
                                    style={{ background: "#212529", display: "inline" }}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2, height: 55 }}
                                >
                                    Wyślij
                                </Button>
                            </div>
                        </div>

                    </div>
                </Modal>

                <div className="row row-flex-style">
                    <Sidemenu page="addressBook" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                        <h3 className="main-color-text" style={{ display: "inline" }}>Baza kontaktów</h3>
                        <Button onClick={() => { this.addBook() }} style={{ background: "#212529", display: "inline", float: "right" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 15, fontWeight: "bold" }}>
                            <i className="fa fa-plus"></i> <span style={{ marginLeft: 10 }}>Dodaj grupę</span>
                        </Button>
                        <hr />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col-10">Nazwa</th>
                                    <th scope="col-2">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.contactBooks.map((book, i) => {
                                    return <tr key={i}>

                                        <td className="col-8"><Link to={{ pathname: `/contacts` }} state={{ bookId: book.id }}>{book.name}<span style={{ marginLeft: "5px" }}>({book.contacts?.length ?? 0})</span></Link></td>
                                        <td className="col-4" style={{ textAlign: "end", paddingRight: "0px" }}>
                                            <Button onClick={() => { this.showSendModal(book) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                                wyślij wiadomość
                                            </Button>
                                            <Button onClick={() => { this.editBook(book) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                                edycja
                                            </Button>
                                            <Button onClick={() => { this.deleteBook(book) }} style={{ background: "#212529" }} type="submit" variant="contained" sx={{ ml: 1, fontSize: 10 }}>
                                                Usuń
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
