import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/sms/';

class SmsService {
    send(data) {
        return axios
            .post(API_URL + "send.json", JSON.stringify(data), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas wysyłania wiadomości.");
                };
            });
    }

    getList() {
        return axios
            .get(API_URL + "getAll", { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas pobierania listy smsów.");
                };
            });
    }

    getListWithSearchPhase(page, rowsPerPage, search, dir, col, from, to) {
        return axios
            .post(API_URL + "getListWithSearchPhase", JSON.stringify({ search: search, page: page, take: rowsPerPage, direction: dir, column: col, dateFrom: from, dateTo: to }), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas pobierania listy smsów.");
                };
            });
    }

    info(smsId) {
        return axios.get(API_URL + 'info/' + smsId);
    }

    getChartData(json) {
        return axios
            .post(API_URL + "getChartData", JSON.stringify(json), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas pobierania danych do wykresu.");
                };
            });
    }
}

export default new SmsService();