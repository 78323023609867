const OrderStatus = () => {

    return (
        <div className="container text-center" style={{ marginTop: '10%' }}>
            <h1>Przetwarzanie płatności</h1>
            <br />
            <h3>Dziękujemy za zamówienie. Aktualnie płatność jest przetwarzana. <br />Zakupione kredyty pojawią się na koncie jak tylko ten proces zostanie zakończony.</h3>
        </div >
    );
}

export default OrderStatus;