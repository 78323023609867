import { faDollar, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { Component } from 'react';
import OrderService from "./../../services/order-service";
import Sidemenu from './Sidemenu';
import Modal from 'react-modal';

const customStyles = {
    content: {
        height: "180px",
        width: "700px",
        margin: 'auto',
    },
};
export class Wallet extends Component {
    static displayName = Wallet.name;

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            isLoading: true,
            order: null,
            orders: []
        };

        this.getOrders();
    }


    async getOrders() {
        if (this.state.isLoading === false) return;
        let result = await OrderService.getOrders();
        this.setState({ orders: result.data, isLoading: false });
    }

    getStatusName(order) {
        switch (order.status) {
            case 0:
                return (86400 - ((Date.now() - new Date(order.createdAt)) / 1000)) <= 0 ? "Błąd płatności" : "Oczekuje na opłacenie";
            case 1:
                return "Anulowano";
            case 2:
                return "Zakończono";
            default:
                return (86400 - ((Date.now() - new Date(order.createdAt)) / 1000)) <= 0 ? "Błąd płatności" : "W trakcie";
        }
    }

    tryPay() {
        window.location = this.state.order.paymentUrl;
    }

    showPaymentModalConfirmation(order) {
        this.setState({ isModalOpen: true });
        this.setState({ order: order });
    }

    openInvoiceInNewTab(order) {
        window.open(order.invoiceUrl, "_blank", "noreferrer");
    }

    async downloadInvoice(order) {
        await OrderService.getInvoice(order.id);
    }

    onCloseModal() {
        this.setState({ isModalOpen: false });
        this.setState({
            order: null
        });
    }

    returnAction(o) {
        switch (o.status) {
            case 0:
            case 3:
            case 4:

                return (86400 - ((Date.now() - new Date(o.createdAt)) / 1000)) <= 0
                    ? null
                    : <Button
                        aria-label="try to pay again"
                        onClick={() => this.showPaymentModalConfirmation(o)}
                        style={{ background: "#212529", margin: "0px" }}
                        type="submit"
                        variant="contained"
                        className="text-white"
                        sx={{ mt: 3, mb: 2 }}>
                        Opłać zamówienie
                    </Button>;
            case 2:
                return o.invoiceUrl == null || o.invoiceUrl === undefined || o.invoiceUrl === ""
                    ? null :
                    <Button
                        aria-label="download invoice pdf"
                        onClick={() => this.openInvoiceInNewTab(o)}
                        style={{ background: "#212529", margin: "0px" }}
                        type="submit"
                        variant="contained"
                        className="text-white"
                        sx={{ mt: 3, mb: 2 }}>
                        Pobierz fakture VAT
                    </Button>;
            case 1:
            default:
                return "";
        }
    }

    isBolded(order) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('orderId') !== undefined) {
            return order.id === urlParams.get('orderId');
        }
        return false;
    }

    render() {
        return (
            <div className="container-fluid">
                <Modal
                    isOpen={this.state.isModalOpen}
                    style={customStyles}
                    contentLabel="Dodaj kredyty"
                >
                    <div className="ml-auto float-right" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => this.onCloseModal()}
                        >
                            <i className="fa-light fa-close"></i>
                        </Button>
                    </div>
                    <p className="mt-3">Czy chcesz opłacić zamówienie na "{(this.state.order == null ? "" : this.state.order?.product?.name)}" z {(this.state.order == null ? "" : this.state.order.createdAt.replace('T', ' ').split('.')[0])} {(this.state.order == null ? "" : this.state.order.createdAt.replace('T', ' ').split('.')[1])}</p>
                    <div className="ml-auto float-right d-inline" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => this.tryPay()}
                        >
                            Tak
                        </Button>
                    </div>
                    <div className="m-3 d-inline" style={{ width: "fit-content" }}>
                        <Button
                            style={{ background: "#212529" }}
                            type="submit"
                            variant="contained"
                            onClick={() => this.onCloseModal()}
                        >
                            Nie
                        </Button>
                    </div>
                </Modal>
                <div className="row row-flex-style">
                    <Sidemenu page="wallet" />
                    <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                        <h3 className="main-color-text">Zamówienia</h3>
                        <hr />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col-2">Status</th>
                                    <th scope="col-2">Product</th>
                                    <th scope="col-1">Cena (zł)</th>
                                    <th scope="col-1">Ilość (kredyty)</th>
                                    <th scope="col-2">Zamówiono</th>
                                    <th scope="col-2">Opłacono</th>
                                    <th scope="col-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orders.map((o, i) => {
                                    return <tr key={i}>
                                        <td className={this.isBolded(o) ? "new-order col-2" : "col-2"}>{this.getStatusName(o)}</td>
                                        <td className={this.isBolded(o) ? "new-order col-2" : "col-2"}>{o.product.name}</td>
                                        <td className={this.isBolded(o) ? "new-order col-1" : "col-1"}>{o.price}</td>
                                        <td className={this.isBolded(o) ? "new-order col-1" : "col-1"}>{o.quantity}</td>
                                        <td className={this.isBolded(o) ? "new-order col-2" : "col-2"}>{o.createdAt.replace('T', ' ').split('.')[0]}</td>
                                        <td className={this.isBolded(o) ? "new-order col-2" : "col-2"}>{o.paidAt?.replace('T', ' ')?.split('.')[0]}</td>
                                        <td className={this.isBolded(o) ? "new-order col-2" : "col-2"}>{this.returnAction(o)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
