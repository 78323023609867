import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/contactbook/';

class ContactBookService {

    getContactBook(id) {
        return axios.get(API_URL + 'get/' + id, { headers: authHeader() });
    }

    getAllContactBooks() {
        return axios.get(API_URL + 'getall', { headers: authHeader() });
    }

    addContactBook(data) {
        return axios
            .post(API_URL + 'add', JSON.stringify(data), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas dodawania bazy klientów.");
                };
            });
    }

    updateContactBook(data) {
        return axios
            .put(API_URL + 'update', JSON.stringify(data), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas edycji bazy klientów.");
                };
            });
    }

    deleteContactBook(id) {
        return axios.delete(API_URL + 'delete/' + id, { headers: authHeader() });
    }

    getAllContacts() {
        return axios.get(API_URL + 'contact/getall', { headers: authHeader() });
    }

    getAllContactsByBookId(id) {
        return axios.get(API_URL + 'contact/getallfrombook/' + id, { headers: authHeader() });
    }

    addContact(data) {
        return axios
            .post(API_URL + 'contact/add', JSON.stringify({ id: data.id, firstName: data.firstName, lastName: data.lastName, companyName: data.companyName, phonePrefix: data.phonePrefix, phone: data.phone, email: data.email, contactBooks: data.contactBooks.map(d => d.id) }), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas dodawaniea klienta.");
                };
            });
    }

    updateContact(data) {
        return axios
            .put(API_URL + 'contact/update', JSON.stringify({ id: data.id, firstName: data.firstName, lastName: data.lastName, companyName: data.companyName, phonePrefix: data.phonePrefix, phone: data.phone, email: data.email, contactBooks: data.contactBooks.map(d => d.id) }), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas edycji klienta.");
                };
            });
    }

    deleteContact(id) {
        return axios.delete(API_URL + 'contact/delete/' + id, { headers: authHeader() });
    }

    sendMessageToContactBook(data) {
        return axios
            .post(API_URL + 'send', JSON.stringify(data), { headers: authHeader() })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas wsyłania wiadomości.");
                };
            });
    }

    importContactsFile(id, formData) {



        return axios
            .post(API_URL + 'import/' + id, formData, { headers: authHeader(false) })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd importu kontaktów.");
                };
            });
    }
}

export default new ContactBookService();