import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TablePagination } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import SmsService from '../../services/sms-service';
import Sidemenu from './Sidemenu';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const Sms = () => {
    const [loading, setLoading] = useState(true);
    const [smsList, setSmsList] = useState([]);


    const [direction, setDirection] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dateSelector, setDateSelector] = useState(-1);

    const getDefaultDate = () => {
        var d = new Date(Date.now()),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year - 1, month, day].join('-');

    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const [dateFrom, setDateFrom] = useState(getDefaultDate());
    const [dateTo, setDateTo] = useState(formatDate(Date.now()));

    const getSmses = async (newPage = null, dir = null, col = null, rows = null, from = null, to = null) => {
        let search = document.getElementById("search")?.value ?? null;
        let result = await SmsService.getListWithSearchPhase(newPage ?? page, rows ?? rowsPerPage, search, dir ?? direction, col ?? selectedColumn, from ?? dateFrom ?? getDefaultDate(), to ?? dateTo ?? formatDate(Date.now()));
        setSmsList(result.data.page);
        setCount(result.data.count);
        setLoading(false);
    }

    const getStatusName = (status) => {
        let errorMessage;

        switch (status) {
            case 2:
                errorMessage = "W trakcie wysyłania";
                break;
            case 1:
                errorMessage = "oczekwanie na wysyłkę...";
                break;
            case 0:
                errorMessage = "Operacja zakończona pomyślnie (Ok)";
                break;
            case -1:
                errorMessage = "Błąd logowania (LoginFailed)";
                break;
            case -2:
                errorMessage = "Niewystarczające uprawnienia (InsufficientPermissions)";
                break;
            case -10:
                errorMessage = "Wewnętrzny błąd bramy SMS (InternalErrorSmsGw)";
                break;
            case -12:
                errorMessage = "Nieprawidłowa wartość parametru (InvalidParameterValue)";
                break;
            case -14:
                errorMessage = "Nieprawidłowy format danych (InvalidDataFormat)";
                break;
            case -15:
                errorMessage = "Błąd połączenia z bazą danych (DatabaseConnectionError)";
                break;
            case -21:
                errorMessage = "Wiadomość SMS jest zbyt długa (SmsMessageTooLong)";
                break;
            case -22:
                errorMessage = "Brak reguł do wyboru linka (NoRulesToSelectLink)";
                break;
            case -23:
                errorMessage = "Usługa nie znaleziona (ServiceNotFound)";
                break;
            case -24:
                errorMessage = "Nieaktywna usługa (InactiveService)";
                break;
            case -31:
                errorMessage = "Nieprawidłowe ID potwierdzonej wiadomości (IncorrectConfirmedMessageID)";
                break;
            case -41:
                errorMessage = "Wiadomość już przekazana i nie może być anulowana (MessageAlreadyForwardedCannotBeCanceled)";
                break;
            case -61:
                errorMessage = "Mailing jest już zakończony (MailingAlreadyClosed)";
                break;
            case -62:
                errorMessage = "Przesyłka nie znaleziona lub zakończona (ShipmentNotFoundOrCompleted)";
                break;
            case -63:
                errorMessage = "Brak zawartości dla wysłanej wiadomości SMS (NoContentForSentSms)";
                break;
            case -71:
                errorMessage = "Błąd połączenia systemu (SystemConnectionError)";
                break;
            case -98:
                errorMessage = "Nieprawidłowy tytuł (WrongCaption)";
                break;
            case -99:
                errorMessage = "Niewystarczająca liczba kredytów (NotEnoughTokens)";
                break;
            default:
                errorMessage = "Nieznany błąd";
                break;
        }
        return errorMessage;
    }

    const sort = (column) => {

        let direction = "";

        switch (column) {
            case "to":
                direction = colHasClass(column);
                resetSort();
                if (direction === "down" || direction === "") {
                    direction = "up";
                } else {
                    direction = "down";
                }
                break;
            case "from":
                direction = colHasClass(column);
                resetSort();
                if (direction === "down" || direction === "") {
                    direction = "up";
                } else {
                    direction = "down";
                }
                break;
            case "msg":
                direction = colHasClass(column);
                resetSort();
                if (direction === "down" || direction === "") {
                    direction = "up";
                } else {
                    direction = "down";
                }
                break;
            case "sentAt":
                direction = colHasClass(column);
                resetSort();
                if (direction === "down" || direction === "") {
                    direction = "up";
                } else {
                    direction = "down";
                }
                break;
            case "status":
                direction = colHasClass(column);
                resetSort();
                if (direction === "down" || direction === "") {
                    direction = "up";
                } else {
                    direction = "down";
                }
                break;
            default:
                break;
        }

        setPage(0);
        setDirection(direction);
        setSelectedColumn(column);
        document.getElementById("col" + column).classList.add(direction);
        getSmses(0, direction, column);
    }

    const colHasClass = (column) => {
        var col = document.getElementById("col" + column);

        if (col.classList.contains("up")) return "up";
        if (col.classList.contains("down")) return "down";
        return "";

    }

    const resetSort = () => {

        document.getElementById("colto").classList.remove("up");
        document.getElementById("colto").classList.remove("down");

        document.getElementById("colfrom").classList.remove("up");
        document.getElementById("colfrom").classList.remove("down");

        document.getElementById("colmsg").classList.remove("up");
        document.getElementById("colmsg").classList.remove("down");

        document.getElementById("colsentAt").classList.remove("up");
        document.getElementById("colsentAt").classList.remove("down");

        document.getElementById("colstatus").classList.remove("up");
        document.getElementById("colstatus").classList.remove("down");

        document.getElementById("coltokens").classList.remove("up");
        document.getElementById("coltokens").classList.remove("down");

    }

    const addArrowToItem = (col) => {

        if (col === selectedColumn) {
            if (direction !== "up") {
                return <FontAwesomeIcon icon={faArrowUp} />;
            } else {
                return <FontAwesomeIcon icon={faArrowDown} />;
            }
        }

        return null;
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        getSmses(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
        setDirection(null);
        setSelectedColumn(null);
        getSmses(0, null, null, parseInt(event.target.value, 10));
    };

    const handleDateSelector = (value) => {
        setDateSelector(value);

        const today = dayjs();

        let range = [];

        switch (value) {
            case 0:
                console.log("ten tydzień", [today.startOf('week'), today.endOf('week')]);
                range = [today.startOf('week'), today.endOf('week')];
                break;
            case 1:
                const prevWeek = today.subtract(7, 'day');
                console.log("poprzedni tydzień", [prevWeek.startOf('week'), prevWeek.endOf('week')]);
                range = [prevWeek.startOf('week'), prevWeek.endOf('week')];
                break;
            case 2:
                console.log("7 dni", [today.subtract(7, 'day'), today])
                range = [today.subtract(7, 'day'), today];
                break;
            case 3:
                console.log("30 dni", [today.subtract(7, 'day'), today])
                range = [today.subtract(7, 'day'), today];
                break;
            case 4:
                console.log("ten miesiąc", [today.startOf('month'), today.endOf('month')])
                range = [today.startOf('month'), today.endOf('month')];
                break;
            case 5:
                const endOfPreviousMonth = today.startOf('month').subtract(1, 'day');
                console.log("poprzedni miesiąc", [endOfPreviousMonth.startOf('month'), endOfPreviousMonth])
                range = [endOfPreviousMonth.startOf('month'), endOfPreviousMonth];
                break;
            case 6:
                console.log("ten rok", [today.startOf('year'), today.endOf('year')])
                range = [today.startOf('year'), today.endOf('year')];
                break;
            case 7:
                const endOfPreviousYear = today.startOf('year').subtract(1, 'day');
                console.log("poprzedni rok", [endOfPreviousYear.startOf('year'), endOfPreviousYear])
                range = [endOfPreviousYear.startOf('year'), endOfPreviousYear];
                break;
            default:
                break;
        }

        setDateFrom(formatDate(range[0]));
        setDateTo(formatDate(range[1]));
        setPage(0);
        getSmses(0, null, null, null, formatDate(range[0]), formatDate(range[1]));
    }

    useEffect(() => {
        if (loading) { getSmses(); }
    });

    return (
        <div className="container-fluid">
            <div className="row row-flex-style">
                <Sidemenu page="sms" />
                <div className="col-sm p-3 min-vh-100" style={{ borderTopLeftRadius: "20px", background: "#f2f2f2", flex: "1 1 auto" }}>
                    <h3 className="main-color-text">Archiwum wysłanych wiadomości</h3>
                    <hr />

                    <div className="row">
                        <div className="col-6">
                            <TextField onChange={() => getSmses()} margin="normal" fullWidth id="search" label="szukaj . . ." name="search" />
                        </div>
                        <div className="col-2 mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker value={dayjs(dateFrom)} defaultValue={dayjs(getDefaultDate())} onChange={(val) => setDateFrom((val.$y) + "-" + ((parseInt(val.$M + 1)) < 10 ? "0" + (parseInt(val.$M) + 1) : (parseInt(val.$M) + 1)) + "-" + (val.$D < 10 ? "0" + val.$D : val.$D))} />
                            </LocalizationProvider>
                        </div>
                        <div className="col-2 mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker value={dayjs(dateTo)} defaultValue={dayjs(formatDate(Date.now()))} onChange={(val) => setDateTo((val.$y) + "-" + ((parseInt(val.$M + 1)) < 10 ? "0" + (parseInt(val.$M) + 1) : (parseInt(val.$M) + 1)) + "-" + (val.$D < 10 ? "0" + val.$D : val.$D))} />
                            </LocalizationProvider>
                        </div>
                        <div className="col-2 mt-3">
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="from-label">Wybierz</InputLabel>
                                <Select
                                    labelId="from-label"
                                    fullWidth
                                    id="type"
                                    name="type"
                                    label="Typ wykresu"
                                    value={dateSelector}
                                    onChange={(e) => { handleDateSelector(e.target.value) }}
                                >
                                    <MenuItem value={-1}>⋅⋅⋅</MenuItem>
                                    <MenuItem value={0}>Ten tydzień</MenuItem>
                                    <MenuItem value={1}>Poprzedni tydzień</MenuItem>
                                    <MenuItem value={2}>7 dni</MenuItem>
                                    <MenuItem value={3}>30 dni</MenuItem>
                                    <MenuItem value={4}>Ten miesiąc</MenuItem>
                                    <MenuItem value={5}>Poprzedni miesiąc</MenuItem>
                                    <MenuItem value={6}>Ten rok</MenuItem>
                                    <MenuItem value={7}>Poprzedni rok</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row fw-bold">
                        <div className="col-1">#</div>
                        <div id="colto" onClick={() => sort("to")} style={{ cursor: "pointer" }} className="col-1">Adresat {addArrowToItem("to")}</div>
                        <div id="colfrom" onClick={() => sort("from")} style={{ cursor: "pointer" }} className="col-1">Nadpis {addArrowToItem("from")}</div>
                        <div id="colmsg" onClick={() => sort("msg")} style={{ cursor: "pointer" }} className="col-4">Treść {addArrowToItem("msg")}</div>
                        <div id="colsentAt" onClick={() => sort("sentAt")} style={{ cursor: "pointer" }} className="col-2">Wysłano {addArrowToItem("sentAt")}</div>
                        <div id="colstatus" className="col-2">Dostarczono {addArrowToItem("updatedAt")}</div>
                        <div id="coltokens" className="col-1">Kredyty</div>
                    </div>

                    <hr />

                    <table className="table">
                        <tbody>
                            {smsList?.map((s, i) => {
                                return <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-1">{s.to}</td>
                                    <td className="col-1">{s.from}</td>
                                    <td title={s.message} className="col-4" >{s.message.length > 50 ? s.message.slice(0, 47) + "..." : s.message}</td>
                                    <td className="col-2">{s.status === 1 ? getStatusName(s.status) : s.sentAt.split("T")[0] + " " + s.sentAt.split("T")[1].split(".")[0]}</td>
                                    <td className="col-2">{s.status === 0 ? s.updatedAt.split("T")[0] + " " + s.updatedAt.split("T")[1].split(".")[0] : ""}</td>
                                    <td className="col-1">{s.tokens}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    <TablePagination
                        component="div"
                        onPageChange={handlePageChange}
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={"Liczba wierszy"}
                        rowsPerPageOptions={[10, 25, 50]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
}

export default Sms;
