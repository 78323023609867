import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/product/';

class ProductService {
    getAll() {
        return axios.get(API_URL + 'get');
    }

    get(id) {
        return axios.get(API_URL + 'get/' + id);
    }
}

export default new ProductService();