const base64ToBytes = (base64) => {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerify = (props) => {

    const verify = () => {
        if (props === "\"\"" || props === "" || props === null || props === undefined) {
            return false;
        }

        let decodedJwt = parseJwt(props);
        return decodedJwt.exp * 1000 < Date.now() ? false : true;
    }

    return verify();

};

export default AuthVerify;