import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import AuthService from "./../../services/auth-service";
import Copyright from "./../Helpers/Copyright";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
export class Register extends Component {
    constructor() {
        super();
        this.state = {
            isRegulationsChecked: false,
            email: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.state.email.length === 0) {
            alert("Email nie możę być pusty.");
            return;
        }

        if (this.state.isRegulationsChecked === false) {
            alert("Przed rejestracją zaakceptuj regulamin strony.");
            return;
        }

        const data = new FormData(event.currentTarget);
        let result = await AuthService.register(data.get('email'));
        if (result.status === 200) {
            alert("Udało się utworzyć konto! Na podany adres email została wysłana wiadomość z linkiem aktywacyjnym.")
        }
    };

    render() {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Rejestracja
                        </Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                autoFocus
                            />
                            <FormControlLabel sx={{ mt: 2 }} required control={<Checkbox value={this.stateisRegulationsChecked} onchanged={(e) => { this.setState({ isRegulationsChecked: !this.state.isRegulationsChecked }) }} />} label="Zapoznałem się z treścią Regulaminu." />

                            <Button
                                style={{ background: "#212529" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Zarejestruj
                            </Button>

                            <Grid container className="mt-4">
                                <Grid item xs>
                                    <Link className="additional-background-color" href="/forgot" variant="body2">
                                        Zapomniałeś hasła?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link className="additional-background-color" href="/login" variant="body2">
                                        {"Posiadasz już konto? Zaloguj się"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>

        );
    }


}
