import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import LoginMenu from './LoginMenu'
import DashboardMenu from './DashboardMenu'
import logo from './../../images/logo_smsJET.png';
export class NavMenu extends Component {
    static displayName = NavMenu.name;
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow navbar navbar-dark" style={{ background: "#212529" }} dark>

                    <div className="container" style={{ display: "flex", flexWrap: "inherit", alignItems: "center", justifyContent: "space-between", marginRight: 0 }}>
                        <NavbarBrand tag={Link} to="/"><img style={{ height: "45px", marginRight: "50px", background: "#212529 !important" }} src={logo} alt="Logo" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow w-100">
                                <NavItem>
                                    <NavLink tag={Link} className="text-white" to="/">Główna</NavLink>
                                </NavItem>
                                <DashboardMenu />
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/ocr">OCR</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/products">Products</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={Link} className="text-white" to="/help">Pomoc</NavLink>
                                </NavItem>
                                <LoginMenu />
                            </ul>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }
}
