import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import AuthService from "./../../services/auth-service";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import BuyProduct from '../Account/BuyProduct';

const customStyles = {
    content: {
        height: "90%",
        width: "50%",
        margin: 'auto',
    },
};

const LoginMenu = () => {
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const logout = () => {
        dispatch(setUser(null));
        AuthService.logout();
    }

    const addTokens = (e) => {
        e.preventDefault();
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)

    }

    const authenticatedView = (user, profilePath, logoutPath) => {
        return (<Fragment>
            <Modal
                isOpen={isModalOpen}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Dodaj kredyty"
            >
                <BuyProduct onClose={closeModal} />
            </Modal>


            <NavItem className='ml-auto'>
                <NavLink tag={Link} className="text-white" to={profilePath}><i className="fa-light fa-user"></i> {user.username}</NavLink>
            </NavItem>
            <NavItem className="">
                <NavLink className="text-white tokens-nav"><i className="fa-light fa-coins"></i> Kredyty: <b>{user.tokens}</b></NavLink>
            </NavItem>
            <NavItem className="">
                <NavLink tag={Link} className="text-white" onClick={(e) => { addTokens(e); } } > + dodaj kredyty</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-white" onClick={logout}><i className="fa-light fa-right-from-bracket"></i> Wyloguj się</NavLink>
            </NavItem>
        </Fragment >);
    }

    const anonymousView = (registerPath, loginPath) => {
        return (<Fragment>
            <NavItem className='ml-auto'>
                <NavLink tag={Link} className="text-white" to={loginPath}>Logowanie</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white" to={registerPath}>Rejestracja</NavLink>
            </NavItem>
        </Fragment>);
    }

    return user == null ? anonymousView("register", "login") : authenticatedView(user, "/dashboard", "/logout");
}

export default LoginMenu;