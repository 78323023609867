import axios from "axios";
import AuthVerify from './../common/auth-verify';
import authHeader from './auth-header';

const API_URL = "api/user/";



class AuthService {
    base64ToBytes(base64) {
        const binString = atob(base64);
        return Uint8Array.from(binString, (m) => m.codePointAt(0));
    }

    bytesToBase64(bytes) {
        const binString = String.fromCodePoint(...bytes);
        return btoa(binString);
    }

    login(usrEmail, password) {
        return axios
            .post(API_URL + "signin", JSON.stringify({ "email": usrEmail, "password": password }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem("user", this.bytesToBase64(new TextEncoder().encode(JSON.stringify(response.data))));
                    return response.data;
                }
                else {
                    window.location = "/login";
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił błąd podczas logowania.");
                };
            });
    }

    refreshUser() {
        if (authHeader().Authorization === undefined) {
            return null;
        }

        return axios
            .get(API_URL + "refresh", { headers: authHeader() })
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem("user", this.bytesToBase64(new TextEncoder().encode(JSON.stringify(response.data))));
                    return response.data;
                }
                else {
                    this.logout();
                }
            }).catch(function (error) {
                if (error.response) {
                    console.log("Błąd podczas autoryzacji.");
                };
            });
    }

    remind(usrEmail) {
        return axios.post(API_URL + "remindpassword", JSON.stringify({ "email": usrEmail }),
            { headers: { 'Content-Type': 'application/json' } });
    }

    remindConfirmation(pass, rpass, token) {
        return axios
            .post(API_URL + "remindpasswordconfirmation",
                JSON.stringify({ "newPassword": pass, "repeatNewPassword": rpass, "token": token }),
                { headers: { 'Content-Type': 'application/json' } })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    alert("Wystąpił problem z potiwerdzeniem zmiany hasła.");
                };
            });
    }

    activationConfirmation(data) {
        return axios
            .post(API_URL + "activationConfirmation", JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.status === 200) {
                    return response;
                }
                else {
                    alert("Wystąpił błąd podczas rejestracji. Jeśli błąd się powtarza skontaktuj się z administracją serwisu.");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    alert("Wystąpił błąd podczas rejestracji. Jeśli błąd się powtarza skontaktuj się z administracją serwisu.");
                };
            });;
    }

    logout() {
        localStorage.removeItem("user");
        window.location = "/login";
    }

    register(email) {
        return axios.post(API_URL + "signup", JSON.stringify({
            "email": email
        }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    alert("Rejestracja zakończona pomyślnie. Link aktywacyjny został wysłany na adres email podany podczas rejestracji.");
                    window.location = "/login";
                }
                else {
                    alert("Wystąpił błąd podczas rejestracji. Jeśli błąd się powtarza skontaktuj się z administracją serwisu.");
                }
            }).catch(function (error) {
                if (error) {
                    alert("Wystąpił błąd podczas rejestracji: " + error.response.data);
                };
            });
    }

    getCurrentUser() {
        try {
            let base64 = localStorage.getItem('user');
            if (base64 === undefined || base64 === null) {
                return null;
            }
            let user = JSON.parse(new TextDecoder().decode(this.base64ToBytes(base64)));
            return (user === undefined || AuthVerify(user?.token) === false)
                ? null
                : user;
        } catch {
            this.logout();
        }
    }
}

export default new AuthService();