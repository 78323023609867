import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';



const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`Copyright ${String.fromCodePoint(0x00A9)} `}
            <Link color="inherit" href="https://smsJET.pl/">
                smsJET.pl
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;