import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { React, useState } from 'react';
import { useParams } from "react-router-dom";
import AuthService from "./../services/auth-service";
import UserService from "./../services/user-service";
import Copyright from "./Helpers/Copyright";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ActivateUser = () => {

    let { token } = useParams();
    const [userData, setUserData] = useState({ firstName: "", lastName: "", phone: "", phonePrefix: "", companyName: "", npass: "", rpass: "", token: token });

    const defaultTheme = createTheme();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (userData.firstName === "" || userData.lastName === "" || userData.npass === "" || userData.rpass === "") {
            alert("Wszystkie dane muszą być uzupełnione.");
            return;
        }

        if (userData.rpass.length < 12 || userData.npass.length < 12) {
            alert("Hasło musi mieć przynajmniej 12 znaków.")
            return;
        }

        if (userData.rpass !== userData.npass) {
            alert("Hasło jest inne niż potwierdzenie. Muszą być takie same.")
            return;
        }

        let result = await AuthService.activationConfirmation(userData);
        console.log(result);
        if (result.status === 200) {
            alert("Aktywacja zakończona sukcesem. Zostaniesz przekierowany do logowania.")
            redirectToLogin();
        }
    };

    const redirectToLogin = () => {
        setTimeout(() => { window.location = "/login"; }, 1000);
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xl">
                <div className="container-fluid mt-3" style={{ background: "#f2f2f2" }}>
                    <div className="row" style={{ background: "#f2f2f2" }}>
                        <div className="col-12">
                            <Typography component="h1" variant="h5">
                                Aktywacja użytkownika
                            </Typography>
                        </div>
                        <div className="col-12">
                            <p>
                                Aby aktywować konto uzupełnij poniższe dane:
                            </p>
                        </div>
                    </div>
                    <div className="row" style={{ background: "#f2f2f2" }}>
                        <div className="col-sm p-3 min-vh-100">
                            <Box className="m-auto" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="row gutters">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="fname"
                                                    label="Imię"
                                                    name="fname"
                                                    type="text"
                                                    value={userData.firstName}
                                                    onChange={(e) => { setUserData({ ...userData, firstName: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="lname"
                                                    label="Nazwisko"
                                                    name="lname"
                                                    type="text"
                                                    value={userData.lastName}
                                                    onChange={(e) => { setUserData({ ...userData, lastName: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="cname"
                                                    label="Nazwa firmy"
                                                    name="cname"
                                                    type="text"
                                                    value={userData.companyName}
                                                    onChange={(e) => { setUserData({ ...userData, companyName: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="form-group">
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="newpass"
                                                    label="Nowe hasło"
                                                    name="newpass"
                                                    type="password"
                                                    value={userData.npass}
                                                    error={userData.npass !== userData.rpass || userData.npass.length < 12}
                                                    onChange={(e) => { setUserData({ ...userData, npass: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="rnewpass"
                                                    label="Powtórz hasło"
                                                    name="rnewpass"
                                                    type="password"
                                                    value={userData.rpass}
                                                    error={userData.npass !== userData.rpass || userData.rpass.length < 12}
                                                    onChange={(e) => { setUserData({ ...userData, rpass: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gutters mt-1">
                                        <div className="col-3">
                                            <div className="text-right">
                                                <Button
                                                    style={{ background: "#212529", padding: 15 }}
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    Zapisz i aktywuj
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
                <Copyright sx={{ mt: "100%", mb: 4 }} />
            </Container>
        </ThemeProvider >

    );

}

export default ActivateUser;