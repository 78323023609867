import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faHome, faCog, faWallet, faSms, faUsers, faPaperPlane, faKey } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import AuthService from "./../../services/auth-service";
import userService from '../../services/user-service';


const Sidemenu = (props) => {

    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch()

    let page = props.page;

    let dashboardClasses = page === "dashboard" ? "d-block p-3 text-decoration-none selected-side-menu-link" : "d-block p-3 text-decoration-none";
    let walletClasses = page === "wallet" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let settingsClasses = page === "settings" ? "nav-link py-3 px-2 selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let smsClasses = page === "sms" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let sendSmsClasses = page === "sendsms" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let usersClasses = page === "users" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let keysClasses = page === "keys" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";
    let bookClasses = page === "addressBook" ? "nav-link py-3 px-2  selected-side-menu-link mt-1" : "nav-link py-3 px-2 mt-1";

    const refreshUser = async () => {
        let user = AuthService.getCurrentUser();
        let result = await userService.getTokens();
        if (result.status === 200) {
            user.tokens = result.data;
            dispatch(setUser(user));
        }
    }

    return (
        <div className="col-sm-auto bg-dark sticky-top" style={{ height: "fit-content" }}>
            <div className="d-flex flex-sm-column flex-row flex-nowrap bg-dark align-items-center sticky-top">
                <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={dashboardClasses} to="/dashboard"><i className="fa-light fa-house"></i><span>Start</span></NavLink>
                    </li>
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={bookClasses} to="/address-book"><i className="fa-light fa-address-book"></i><span>Baza kontaktów</span></NavLink>
                    </li>
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={sendSmsClasses} to="/sendsms"><i className="fa-light fa-paper-plane-top"></i><span>Bramka SMS</span></NavLink>
                    </li>
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={smsClasses} to="/sms-list"><i className="fa-light fa-comment-sms"></i><span>Archiwum</span></NavLink>
                    </li>
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={walletClasses} to="/orders"><i className="fa-light fa-money-check-dollar"></i><span>Zamówienia</span></NavLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavLink tag={Link} className={usersClasses} to="/users"><i className="fa-light fa-users"></i><span>Użytkownicy</span></NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={keysClasses} to="/keys"><i className="fa-light fa-house"></i><span>Klucze</span></NavLink>
                    </li>
                    <li>
                        <NavLink onClick={(e) => refreshUser()} tag={Link} className={settingsClasses} to="/settings"><i className="fa-light fa-gear"></i><span>Ustawienia</span></NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );

}
export default Sidemenu;