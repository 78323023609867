import React, { Component } from 'react';

export class Regulations extends Component {
    static displayName = Regulations.name;

    render() {
        return (
            <div>
                <h1>Regulations!</h1>
            </div>
        );
    }
}
