import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';

const DashboardMenu = () => {
    const user = useSelector((state) => state.user.value);

    const authenticatedView = (user, profilePath, logoutPath) => {
        return <NavItem>
            <NavLink tag={Link} className="text-white" to="/dashboard">Panel administracyjny</NavLink>
        </NavItem>;
    }

    const anonymousView = (registerPath, loginPath) => {
        return null;
    }

    return user == null ? anonymousView("register", "login") : authenticatedView(user, "/dashboard", "/logout");
}

export default DashboardMenu;